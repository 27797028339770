document.addEventListener("DOMContentLoaded", () => {
  const animationButton = document.getElementById("animation-button");
  const animationCursor = document.getElementById("animation-cursor");
  const animationNetwork = document.querySelector(".animation-network");
  const animationHeader = document.querySelector(".animation-header");
  const animationPosts = document.querySelectorAll(".animation-post");
  const animationAvatars = document.querySelectorAll(".animation-post-avatar");

  function animateNetwork() {
    animationNetwork.style.opacity = "1";

    setTimeout(() => {
      animationHeader.style.transform = "translateY(0)";
    }, 200);

    animationPosts.forEach((post, index) => {
      setTimeout(() => {
        post.style.opacity = "1";
        post.style.transform = "translateY(0)";

        const postElements = post.querySelectorAll(
          ".animation-post-avatar, .animation-username, .animation-post-content, .animation-action"
        );
        postElements.forEach((element, elementIndex) => {
          setTimeout(() => {
            element.style.opacity = "1";
          }, elementIndex * 100);
        });
      }, 1000 + index * 300);
    });
  }

  function resetAnimation() {
    animationButton.style.opacity = "1";
    animationButton.style.transform = "translate(-50%, -50%)";
    animationNetwork.style.opacity = "0";
    animationHeader.style.transform = "translateY(-100%)";

    animationPosts.forEach((post) => {
      post.style.opacity = "0";
      post.style.transform = "translateY(20px)";
      const postElements = post.querySelectorAll(
        ".animation-post-avatar, .animation-username, .animation-post-content, .animation-action"
      );
      postElements.forEach((element) => {
        element.style.opacity = "0";
      });
    });
  }

  function runAnimation() {
    animationCursor.style.animation = "animation-cursor-move 2s forwards";

    setTimeout(() => {
      animationCursor.style.animation = "animation-cursor-click 0.2s";
      animationButton.style.animation = "animation-click 0.2s";
    }, 1000);

    setTimeout(() => {
      animationButton.style.opacity = "0";
      animationButton.style.transform = "translate(-50%, -50%) scale(0)";
      animationCursor.style.opacity = "0";
      animateNetwork();
    }, 1500);

    setTimeout(resetAnimation, 5000);
    setTimeout(runAnimation, 5500);
  }

  runAnimation();
});
